import React, {Component, Fragment} from 'react'
import {withRouter} from 'react-router-dom'
import Routes from '../../routes/common'
import Header from '../../components/Header/Header'
import ZipcodeData from '../../services/zipcodeApi'
import Footer from '../../components/Footer/Footer'
import Navigation from '../../components/Navigation/Navigation'

class Calculator extends Component {
  state = {
    userInput: {
      firstname: '',
      lastname: '',
      email: '',
      zipcode: null,
      placeName: '',
      currentConsumption: 0,
      highVoltageCabine: '',
      connectionGas: '',
      typeHeatSource: '',
      floorHeating: '',
      vehicleEH: '',
      electricBoiler: '',
      ageBuilding: '',
      poolAvailable: '',
      typeHouse: '',
      ownerOrRenting: '',
      energySituation: '',
      solarAvailable: '',
      airHeatPumpAvailable: '',
      inverterPower: '',
      province: '',
      region: '',
      industry: '',
      typeCustomer: '',
      profession: '',
      jobTitle: '',
      knowTheUsage: '',
      estimatedKWhConsumption: '',
      consumerRateLocation: '',
      industryOption: [],
      typeCustomerOption: [],
      professionOption: [],
      jobTitleOption: [],
    },
    loading: false,
    currentStep: 0,
  }

  // input or radio
  onChangeHandler = e => {
    const {name, value} = e.target
    this.setState({
      userInput: {
        ...this.state.userInput,
        [name]: value,
      },
    })
  }

  onChangeHandlerCheckBox = e => {
    const {name, checked} = e.target
    this.setState({
      userInput: {
        ...this.state.userInput,
        [name]: checked,
      },
    })
  }

  // Handle changer for dropdown input
  handleChangeSelect = event => {
    const {name, value, optionName} = event
    this.setState({
      userInput: {
        ...this.state.userInput,
        [name]: value,
        [optionName]: event,
      },
    })
  }

  // To the next step when choosing the answer
  onClickHandler = event => {
    if (event.target.type === 'submit') {
      this.handleSteps()
    } else {
      setTimeout(() => {
        this.handleSteps()
      }, 300)
    }
  }

  // Get values from AutoComplete Zipcode
  handleParentData = formModel => {
    this.setState({
      userInput: {
        ...this.state.userInput,
        zipcode: formModel.zipcode,
        placeName: formModel.placeName,
        province: formModel.province,
        region: formModel.region,
        consumerRateLocation: parseFloat(
          formModel.consumerRate
            .slice(0, formModel.consumerRate.length - 2)
            .replace(',', '.')
            .replace(' ', ''),
        ),
      },
    })
  }

  // Default value for InventerPower
  defaultValueSolarPower = () => {
    this.setState({
      userInput: {
        ...this.state.userInput,
        inverterPower: 4.5,
      },
    })
  }

  handleSteps = () => {
    switch (this.props.history.location.pathname) {
      case '/zipcode':
        this.props.history.push('/type-customer')
        break
      case '/type-customer':
        if (this.state.userInput.typeCustomer === 'School') {
          window.location.assign('https://elugie.com/solutions/schools/')
        } else if (this.state.userInput.typeCustomer === 'Overheid') {
          window.location.assign('https://elugie.com/solutions/government/')
        } else if (this.state.userInput.typeCustomer === 'Andere') {
          window.location.assign('https://elugie.com/contacts/')
        } else {
          this.props.history.push('/know-the-usage')
        }
        break
      case '/know-the-usage':
        if (this.state.userInput.knowTheUsage === 'Yes') {
          this.props.history.push('/estimated-kWh-consumption')
        } else {
          this.props.history.push('/current-consumption')
        }
        break
      case '/estimated-kWh-consumption':
        this.props.history.push('/type-heat-source')
        break
      case '/current-consumption':
        this.props.history.push('/type-heat-source')
        break
      case '/type-heat-source':
        if (this.state.userInput.typeHeatSource !== 'Gas') {
          this.props.history.push('/connection-gas')
        } else {
          this.props.history.push('/floor-heating')
        }
        break
      case '/connection-gas':
        if (this.state.userInput.typeHeatSource === 'Warmtepomp') {
          this.props.history.push('/air-heat-pump-available')
        } else {
          this.props.history.push('/floor-heating')
        }
        break
      case '/air-heat-pump-available':
        this.props.history.push('/floor-heating')
        break
      case '/floor-heating':
        this.props.history.push('/electric-boiler')
        break
      case '/electric-boiler':
        this.props.history.push('/vehicle-e-h')
        break
      case '/vehicle-e-h':
        this.props.history.push('/energy-situtation')
        break
      case '/energy-situtation':
        this.props.history.push('/owner-or-renting')
        break
      case '/owner-or-renting':
        if (this.state.userInput.typeCustomer === 'KMO') {
          this.props.history.push('/industry')
        } else {
          this.props.history.push('/age-building')
        }
        break

      case '/solar-available':
        if (this.state.userInput.solarAvailable === 'Yes') {
          this.props.history.push('/inverter-power')
        } else {
          this.props.history.push('/user-form')
        }
        break
      case '/inverter-power':
        this.props.history.push('/user-form')
        break

      // KMO path
      case '/industry':
        this.props.history.push('/high-voltage-cabine')
        break
      case '/high-voltage-cabine':
        this.props.history.push('/job-title')
        break
      case '/job-title':
        this.props.history.push('/solar-available')
        break

      // Vrij beroep and Particuleir path
      case '/age-building':
        this.props.history.push('/pool-available')
        break

      // Vrij beroep only
      case '/profession':
        if (this.state.userInput.typeHouse !== 'Appartement') {
          this.props.history.push('/solar-available')
        } else {
          this.props.history.push('/user-form')
        }
        break

      // Particulier only
      case '/pool-available':
        this.props.history.push('/type-house')
        break
      case '/type-house':
        if (this.state.userInput.typeCustomer === 'Vrij beroep') {
          this.props.history.push('/profession')
        } else if (this.state.userInput.typeHouse !== 'Appartement') {
          this.props.history.push('/solar-available')
        } else {
          this.props.history.push('/user-form')
        }
        break
    }
  }

  render() {
    console.log(this.state.userInput.typeHouse)
    return (
      <Fragment>
        <Header />
        <Routes
          handleParentData={this.handleParentData}
          items={ZipcodeData}
          onChangeHandler={this.onChangeHandler}
          handleChangeSelect={this.handleChangeSelect}
          defaultValueSolarPower={this.defaultValueSolarPower}
          onChangeHandlerCheckbox={this.onChangeHandlerCheckBox}
          dataInput={this.state.userInput}
          onClickHandler={this.onClickHandler}
        />
        <Footer />
      </Fragment>
    )
  }
}

export default withRouter(Calculator)
