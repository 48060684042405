import styled from 'styled-components'

export const HeroTitle = styled.h1`
  color: #212850;
  font-family: HelveticaNeueLTPro-BlkCn, sans-serif;
  text-align: center;
  margin-bottom: 12px;
`
export const Title = styled.h2`
  color: #212850;
  font-family: HelveticaNeueLTPro-BlkCn, sans-serif;
  font-size: 30px;
  margin-bottom: ${({marginBottom}) => marginBottom || '15px'};
  margin-top: ${({marginTop}) => marginTop || '1px'};
  text-align: ${({align}) => align || 'left'};
`
export const SubTitle = styled.p`
  color: ${({color}) => color || '#212850'};
  font-size: ${({fontSize}) => fontSize || '18px'};
  text-align: ${({align}) => align || 'center'};
  opacity: ${({opacity}) => opacity || '1'};
  margin-bottom: ${({marginBottom}) => marginBottom || '1px'};
  margin-top: ${({marginTop}) => marginTop || '1px'};
`

export const BoldTitle = styled(SubTitle)`
  font-family: HelveticaNeueLTPro-Md, sans-serif;
  font-weight: bold;
`
