import React from 'react'
import {useState} from 'react'
import * as Sentry from '@sentry/browser'
import {withRouter} from 'react-router-dom'
import {
  Col,
  Form,
  Row,
  Spinner,
  InputGroup,
  FormControl,
} from 'react-bootstrap'
import {CardElement, useStripe, useElements} from '@stripe/react-stripe-js'
import Button from '../../components/UI/Button/Button'
import {SubTitle, BoldTitle} from  '../../components/UI/Title/Title'
import styled from 'styled-components'
import axios from 'axios'

import {validateEmail} from '../../helpers'

const DivLinebreak = styled.div`
  border-top: 0.5px solid #c6c9d7;
  padding-top: 20px;
  margin-top: 10px;
  padding-bottom: 15px;
`

const InputCardElementContainer = styled(Form.Group)`
  border: ${({border}) => border || '1px solid #dfe1e5'};
  padding: 10px 20px;
`

const CheckoutForm = props => {
  const [companyName, setCompanyName] = useState('')
  const [vatNumber, setVatNumber] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [city, setCity] = useState('')
  const [phone, setPhone] = useState('')
  const [line1, setLine1] = useState('')
  const [postalCode, setPostalCode] = useState('')
  const [name, setName] = useState('')

  const [isProcessing, setProcessingTo] = useState(false)
  const [checkoutError, setCheckoutError] = useState('')
  const [validated, setValidated] = useState(false)
  const [cardElementEmpty, setCardElementEmpty] = useState(true)

  const stripe = useStripe()
  const elements = useElements()

  const handleCardDetailsChange = ev => {
    ev.error ? setCheckoutError(ev.error.message) : setCheckoutError()

    if (ev.empty) {
      setCardElementEmpty(true)
    } else {
      setCardElementEmpty(false)
    }
  }

  const handleFormSubmit = async ev => {
    ev.preventDefault()

    const form = ev.currentTarget
    if (form.checkValidity() === false) {
      ev.stopPropagation()
    }

    setValidated(true)

    const billingDetails = {
      name: name,
      email: email,
      phone: phone,
      address: {
        city: city,
        line1: line1,
        postal_code: postalCode,
        country: 'BE',
      },
    }

    let input_data = {
      offer_id: props.match.params.simulationId,
      company_name: companyName,
      company_vat_number: `BE${vatNumber}`,
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone_number: phone,
      name: name,
      address: {
        line1: line1,
        city: city,
        postal_code: postalCode,
        country: 'BE',
      },
    }

    setProcessingTo(true)

    const cardElement = elements.getElement('card')

    try {
      const {data: clientSecret} = await axios.post(
        'https://eluapi.com/api/stripe/payment_intents/',
        {
          input_data,
        },
      )

      const paymentMethodReq = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: billingDetails,
      })

      if (paymentMethodReq.error) {
        setCheckoutError(paymentMethodReq.error.message)
        setProcessingTo(false)
        return
      }

      const {error} = await stripe.confirmCardPayment(clientSecret, {
        payment_method: paymentMethodReq.paymentMethod.id,
      })

      if (error) {
        setCheckoutError(error.message)
        setProcessingTo(false)
        return
      }
      window.location.assign('https://elugie.com/reservation/success/')
    } catch (err) {
      setCheckoutError(err.message)
      setProcessingTo(false)
      Sentry.withScope(scope => {
        Sentry.captureException(err.message)
      })
    }
  }

  const cardElementOptions = {
    style: {
      base: {
        fontSize: '16px',
      },
      invalid: {},
    },
    hidePostalCode: true,
  }

  const disableButton =
    companyName &&
    vatNumber &&
    firstName &&
    lastName &&
    phone &&
    line1 &&
    postalCode &&
    name &&
    validateEmail(email) &&
    !cardElementEmpty

  return (
    <Form noValidate validated={validated} onSubmit={handleFormSubmit}>
      <DivLinebreak>
        <Form.Group>
          <Form.Control
            required
            type='text'
            value={companyName}
            name='companyName'
            placeholder='Naam bedrijf'
            onChange={e => setCompanyName(e.target.value)}
          />
        </Form.Group>
        <InputGroup className='mb-3 mr-sm-2'>
          <InputGroup.Prepend>
            <InputGroup.Text>BE</InputGroup.Text>
          </InputGroup.Prepend>
          <FormControl
            required
            type='text'
            value={vatNumber}
            name='vatNumber'
            onChange={e => setVatNumber(e.target.value)}
            placeholder='BTW-nummer'
          />
        </InputGroup>
        {/*<Form.Group>*/}
        {/*  <Form.Control*/}
        {/*    required*/}
        {/*    type='text'*/}
        {/*    value={vatNumber}*/}
        {/*    name='vatNumber'*/}
        {/*    placeholder='Btw-nummer (BE...)'*/}
        {/*    onChange={e => setVatNumber(e.target.value)}*/}
        {/*  />*/}
        {/*</Form.Group>*/}
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Control
              required
              type='text'
              value={firstName}
              name='firstName'
              placeholder='Voornaam'
              onChange={e => setFirstName(e.target.value)}
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Control
              required
              type='text'
              value={lastName}
              name='lastName'
              placeholder='Achternaam'
              onChange={e => setLastName(e.target.value)}
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Control
              required
              type='email'
              name='email'
              value={email}
              placeholder='E-mailadres'
              onChange={e => setEmail(e.target.value)}
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Control
              required
              type='tel'
              name='phone'
              value={phone}
              placeholder='Telefoonnummer'
              onChange={e => setPhone(e.target.value)}
            />
          </Form.Group>
        </Form.Row>
        <Form.Group>
          <Form.Control
            required
            type='text'
            name='line1'
            value={line1}
            placeholder='Adresregel'
            onChange={e => setLine1(e.target.value)}
          />
        </Form.Group>
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Control
              required
              type='text'
              name='city'
              value={city}
              placeholder='Stad'
              onChange={e => setCity(e.target.value)}
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Control
              required
              type='number'
              name='postalCode'
              value={postalCode}
              placeholder='Postcode'
              onChange={e => setPostalCode(e.target.value)}
            />
          </Form.Group>
        </Form.Row>
      </DivLinebreak>
      <div>
        <BoldTitle align='left'>Kredietkaart</BoldTitle>
      </div>
      <DivLinebreak>
        <Form.Group>
          <Form.Control
            required
            type='text'
            name='name'
            value={name}
            placeholder='Naam op kaart'
            onChange={e => setName(e.target.value)}
          />
        </Form.Group>
        <InputCardElementContainer
          border={checkoutError ? '1px solid #eb1c26' : '1px solid #dfe1e5'}
        >
          <CardElement
            options={cardElementOptions}
            onChange={handleCardDetailsChange}
          />
        </InputCardElementContainer>
        <SubTitle color='#eb1c26' align='left'>
          {checkoutError}
        </SubTitle>
        <Row style={{textAlign: 'center', paddingTop: '30px'}}>
          <Col md={12}>
            <Button disabled={!disableButton || isProcessing || !stripe}>
              {isProcessing ? (
                <div>
                  <Spinner
                    as='span'
                    animation='grow'
                    size='sm'
                    role='status'
                    aria-hidden='true'
                  />
                  Uw betaling wordt verwerkt..
                </div>
              ) : (
                'IK WIL RESERVEREN'
              )}
            </Button>
          </Col>
        </Row>
      </DivLinebreak>
    </Form>
  )
}

export default withRouter(CheckoutForm)
