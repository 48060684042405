import React, {Fragment} from 'react'
import {Row, Col, Container} from 'react-bootstrap'
import Select from 'react-select'

import {CardInput} from '../../UI/Input/Input'
import {Section} from '../../UI/Section/Section'
import {Title} from '../../UI/Title/Title'
import Navigation from '../../Navigation/Navigation'

const typeCustomer = props => {
  const options = [
    {
      name: 'typeCustomer',
      optionName: 'typeCustomerOption',
      value: 'Vrij beroep',
      label: 'Vrij beroep',
    },
    {
      name: 'typeCustomer',
      optionName: 'typeCustomerOption',
      value: 'Overheid',
      label: 'Overheid',
    },
    {
      name: 'typeCustomer',
      optionName: 'typeCustomerOption',
      value: 'School',
      label: 'School',
    },
    {
      name: 'typeCustomer',
      optionName: 'typeCustomerOption',
      value: 'Andere',
      label: 'Andere',
    },
  ]

  let selector = null

  if (
    props.dataInput.typeCustomer === 'Andere' ||
    props.dataInput.typeCustomer === 'Andere Choice' ||
    props.dataInput.typeCustomer === 'Overheid' ||
    props.dataInput.typeCustomer === 'Vrij beroep' ||
    props.dataInput.typeCustomer === 'School'
  ) {
    selector = (
      <Row>
        <Col style={{paddingTop: '2.3rem'}} md={12}>
          <Select
            value={props.dataInput.typeCustomerOption}
            onChange={props.handleChangeSelect}
            options={options}
            isSearchable={false}
          />
        </Col>
      </Row>
    )
  }
  return (
    <Container>
      <Section>
        <Row>
          <Col md={12}>
            <Title align='center' marginBottom='2.5rem'>
              Wie bent u?
            </Title>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <CardInput
              faIcon='fas fa-home'
              type='radio'
              value='Particulier'
              name='typeCustomer'
              onChange={props.onChangeHandler}
              onClick={props.onClickHandler}
              checked={props.dataInput.typeCustomer === 'Particulier'}
            >
              PARTICULIER
            </CardInput>
          </Col>
          <Col md={4}>
            <CardInput
              faIcon='far fa-building'
              type='radio'
              value='KMO'
              name='typeCustomer'
              onChange={props.onChangeHandler}
              onClick={props.onClickHandler}
              checked={props.dataInput.typeCustomer === 'KMO'}
            >
              KMO
            </CardInput>
          </Col>
          <Col md={4}>
            <CardInput
              faIcon='far fa-question-circle'
              type='radio'
              value='Andere Choice'
              name='typeCustomer'
              onChange={props.onChangeHandler}
              checked={
                props.dataInput.typeCustomer === 'Andere Choice' ||
                props.dataInput.typeCustomer === 'Andere' ||
                props.dataInput.typeCustomer === 'Vrij beroep' ||
                props.dataInput.typeCustomer === 'Overheid' ||
                props.dataInput.typeCustomer === 'School'
              }
            >
              ANDERE
            </CardInput>
          </Col>
        </Row>
        {selector}
        <Navigation
          dataInput={props.dataInput}
          onClick={props.onClickHandler}
        />
      </Section>
    </Container>
  )
}

export default typeCustomer
