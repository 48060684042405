import React from 'react'

import Calculator from './containers/Calculator/Calculator'

import {Elements} from '@stripe/react-stripe-js'
import {loadStripe} from '@stripe/stripe-js'

const stripePromise = loadStripe(process.env.REACT_APP_publishable)

function App() {
  return (
    <Elements stripe={stripePromise}>
      <Calculator />
    </Elements>
  )
}

export default App
