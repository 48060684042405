import React, {Component} from 'react'
import {Route, Switch, Redirect, withRouter} from 'react-router-dom'
import ReactGA from 'react-ga'
import ReactPixel from 'react-facebook-pixel'
import Cookies from 'js-cookie'

import PostalCode from '../components/Steps/PostalCode/PostalCode'
import CurrentConsumption from '../components/Steps/CurrentConsumption/CurrentConsumption'

import KnowTheUsage from '../components/Steps/KnowTheUsage/KnowTheUsage'
import TypeCustomer from '../components/Steps/TypeCustomer/TypeCustomer'
import EstimatedKWhConsumption from '../components/Steps/EstimatedKWhConsumption/EstimatedKWhConsumption'
import SolarAvailable from '../components/Steps/SolarAvailable/SolarAvailable'
import InverterPower from '../components/Steps/InverterPower/InverterPower'
import VehicleEH from '../components/Steps/VehicleEH/VehicleEH'
import ConnectionGas from '../components/Steps/ConnectionGas/ConnectionGas'
import TypeHeatSource from '../components/Steps/TypeHeatSource/TypeHeatSource'
import TypeHouse from '../components/Steps/TypeHouse/TypeHouse'
import FloorHeating from '../components/Steps/FloorHeating/FloorHeating'
import ElectricBoiler from '../components/Steps/ElectricBoiler/ElectricBoiler'
import SMEIndustry from '../components/Steps/SMEIndustry/SMEIndustry'
import HighvoltageCabine from '../components/Steps/HighVoltageCabine/HighVoltageCabine'
import OwnerOrRenting from '../components/Steps/OwnerOrRenting/OwnerOrRenting'
import JobTitle from '../components/Steps/JobTitle/JobTitle'
import PoolAvailable from '../components/Steps/PoolAvailable/PoolAvailable'
import AgeBuilding from '../components/Steps/AgeBuilding/AgeBuilding'
import AirHeatPumpAvailable from '../components/Steps/AirHeatPumpAvailable/AirHeatPumpAvailable'
import EnergySituation from '../components/Steps/EnergySituation/EnergySituation'
import UserForm from '../components/Steps/UserForm/UserForm'
import Result from '../components/Steps/Result/Result'
import LiberalProfession from '../components/Steps/LiberalProfession/LiberalProfession'

let _hsq = (window._hsq = window._hsq || [])

class Routes extends Component {
  componentDidUpdate(prevProps) {
    const path = window.location.pathname + window.location.search

    if (this.props.location !== prevProps.location) {
      _hsq.push(['setPath', path])
      _hsq.push(['trackPageView'])
      ReactGA.pageview(path)
      ReactPixel.pageView()
    }
  }

  render() {
    let pathname = window.location.pathname.slice(0, 7)
    let checkResult = pathname.startsWith('/result')

    let simulationId

    if (!Cookies.get('elugie_sim')) {
      simulationId = window.location.pathname.slice(8, 44)
    } else {
      simulationId = Cookies.get('elugie_sim')
    }

    const {
      handleParentData,
      items,
      dataInput,
      onChangeHandler,
      handleChangeSelect,
      onClickHandler,
      defaultValueSolarPower,
    } = this.props

    return (
      <Switch>
        <Route
          exact
          path='/'
          render={() => (
            <Redirect
              to={{
                pathname: '/zipcode',
              }}
            />
          )}
        />
        <Route
          exact
          path='/pricing'
          render={() =>
            simulationId ? (
              <Redirect
                to={{
                  pathname: `/result/${simulationId}`,
                  state: {
                    menuItems: true,
                  },
                }}
              />
            ) : (
              <Redirect
                to={{
                  pathname: '/zipcode',
                  state: {
                    welcomeAlert: true,
                  },
                }}
              />
            )
          }
        />
        <Route
          path='/zipcode'
          render={() => (
            <PostalCode handleParentData={handleParentData} items={items} />
          )}
        />
        {dataInput.zipcode === null &&
        (checkResult === false || simulationId === undefined) ? (
          <Redirect
            to={{
              pathname: '/zipcode',
              state: {
                reloadStepAlert: true,
              },
            }}
          />
        ) : null}
        <Route
          path='/know-the-usage'
          render={() => (
            <KnowTheUsage
              onChangeHandler={onChangeHandler}
              dataInput={dataInput}
              onClickHandler={onClickHandler}
            />
          )}
        />{' '}
        <Route
          path='/type-customer'
          render={() => (
            <TypeCustomer
              onChangeHandler={onChangeHandler}
              dataInput={dataInput}
              onClickHandler={onClickHandler}
              handleChangeSelect={handleChangeSelect}
            />
          )}
        />
        <Route
          path='/estimated-kWh-consumption'
          render={() => (
            <EstimatedKWhConsumption
              onChangeHandler={onChangeHandler}
              onClickHandler={onClickHandler}
              dataInput={dataInput}
            />
          )}
        />
        <Route
          path='/current-consumption'
          render={() => (
            <CurrentConsumption
              onClickHandler={onClickHandler}
              onChangeHandler={onChangeHandler}
              dataInput={dataInput}
            />
          )}
        />
        <Route
          path='/solar-available'
          render={() => (
            <SolarAvailable
              onChangeHandler={onChangeHandler}
              dataInput={dataInput}
              onClickHandler={onClickHandler}
            />
          )}
        />
        <Route
          path='/inverter-power'
          render={() => (
            <InverterPower
              onChangeHandler={onChangeHandler}
              onClickHandler={onClickHandler}
              defaultValueSolarPower={defaultValueSolarPower}
              dataInput={dataInput}
            />
          )}
        />
        <Route
          path='/connection-gas'
          render={() => (
            <ConnectionGas
              onChangeHandler={onChangeHandler}
              dataInput={dataInput}
              onClickHandler={onClickHandler}
            />
          )}
        />
        <Route
          path='/owner-or-renting'
          render={() => (
            <OwnerOrRenting
              onChangeHandler={onChangeHandler}
              dataInput={dataInput}
              onClickHandler={onClickHandler}
            />
          )}
        />
        <Route
          path='/type-heat-source'
          render={() => (
            <TypeHeatSource
              onChangeHandler={onChangeHandler}
              dataInput={dataInput}
              onClickHandler={onClickHandler}
            />
          )}
        />
        <Route
          path='/type-house'
          render={() => (
            <TypeHouse
              onChangeHandler={onChangeHandler}
              dataInput={dataInput}
              onClickHandler={onClickHandler}
            />
          )}
        />
        <Route
          path='/floor-heating'
          render={() => (
            <FloorHeating
              onChangeHandler={onChangeHandler}
              dataInput={dataInput}
              onClickHandler={onClickHandler}
            />
          )}
        />
        <Route
          path='/electric-boiler'
          render={() => (
            <ElectricBoiler
              onChangeHandler={onChangeHandler}
              dataInput={dataInput}
              onClickHandler={onClickHandler}
            />
          )}
        />
        <Route
          path='/vehicle-e-h'
          render={() => (
            <VehicleEH
              onChangeHandler={onChangeHandler}
              dataInput={dataInput}
              onClickHandler={onClickHandler}
            />
          )}
        />
        <Route
          path='/air-heat-pump-available'
          render={() => (
            <AirHeatPumpAvailable
              onChangeHandler={onChangeHandler}
              dataInput={dataInput}
              onClickHandler={onClickHandler}
            />
          )}
        />
        <Route
          path='/energy-situtation'
          render={() => (
            <EnergySituation
              onChangeHandler={onChangeHandler}
              dataInput={dataInput}
              onClickHandler={onClickHandler}
            />
          )}
        />
        <Route
          path='/industry'
          render={() => (
            <SMEIndustry
              handleChangeSelect={handleChangeSelect}
              onClickHandler={onClickHandler}
              dataInput={dataInput}
            />
          )}
        />
        <Route
          path='/profession'
          render={() => (
            <LiberalProfession
              handleChangeSelect={handleChangeSelect}
              onClickHandler={onClickHandler}
              dataInput={dataInput}
            />
          )}
        />
        <Route
          path='/high-voltage-cabine'
          render={() => (
            <HighvoltageCabine
              onChangeHandler={onChangeHandler}
              dataInput={dataInput}
              onClickHandler={onClickHandler}
            />
          )}
        />
        <Route
          path='/age-building'
          render={() => (
            <AgeBuilding
              onChangeHandler={onChangeHandler}
              dataInput={dataInput}
              onClickHandler={onClickHandler}
            />
          )}
        />
        <Route
          path='/pool-available'
          render={() => (
            <PoolAvailable
              onChangeHandler={onChangeHandler}
              dataInput={dataInput}
              onClickHandler={onClickHandler}
            />
          )}
        />
        <Route
          path='/job-title'
          render={() => (
            <JobTitle
              handleChangeSelect={handleChangeSelect}
              onClickHandler={onClickHandler}
              dataInput={dataInput}
            />
          )}
        />
        <Route
          path='/user-form'
          render={() => (
            <UserForm
              onChangeHandler={onChangeHandler}
              onClickHandler={onClickHandler}
              dataInput={dataInput}
            />
          )}
        />
        <Route
          path='/result/:simulationId'
          render={props => (
            <Result
              {...props}
              dataInput={dataInput}
              onClickHandler={onClickHandler}
              onChangeHandler={onChangeHandler}
              stripe={this.props.stripe}
            />
          )}
        />
        <Route
          path='/result'
          render={() => (
            <Result
              dataInput={dataInput}
              onClickHandler={onClickHandler}
              onChangeHandler={onChangeHandler}
              stripe={this.props.stripe}
            />
          )}
        />
        } /> />
        <Route render={() => <Redirect to='/zipcode' />} />
      </Switch>
    )
  }
}

export default withRouter(Routes)
