import 'react-app-polyfill/ie9'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import {BrowserRouter} from 'react-router-dom'
import {hotjar} from 'react-hotjar'
import ReactPixel from 'react-facebook-pixel'
import * as Sentry from '@sentry/browser'
import ReactGA from 'react-ga'

import './index.css'
import App from './App'

ReactGA.initialize('UA-88379351-1')
hotjar.initialize(1077130, 6)
Sentry.init({
  dsn: 'https://bfed894653b945ae92c554a724ed3f88@sentry.io/2438305',
})

const options = {
  autoConfig: true, // set pixel's autoConfig
  debug: false, // enable logs
}

ReactPixel.init(506855243152596, options)

const app = (
  <BrowserRouter>
    <App />
  </BrowserRouter>
)

ReactDOM.render(app, document.getElementById('root'))
