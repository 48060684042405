import React, {Fragment} from 'react'
import {Row, Col, Container} from 'react-bootstrap'

import {CardInput} from '../../UI/Input/Input'
import Navigation from '../../Navigation/Navigation'
import {Title} from '../../UI/Title/Title'
import {Section} from '../../UI/Section/Section'

const knowTheUse = props => {
  return (
    <Container>
      <Section>
        <Row>
          <Col md={12}>
            <Title align='center' marginBottom='2.5rem'>
              Kent u uw elektriciteitsverbruik in kWh?
            </Title>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <CardInput
              faIcon='far fa-check-circle'
              type='radio'
              value='Yes'
              name='knowTheUsage'
              onChange={props.onChangeHandler}
              onClick={props.onClickHandler}
              checked={props.dataInput.knowTheUsage === 'Yes'}
            >
              JA
            </CardInput>
          </Col>
          <Col md={6}>
            <CardInput
              faIcon='far fa-times-circle'
              type='radio'
              value='No'
              name='knowTheUsage'
              onChange={props.onChangeHandler}
              onClick={props.onClickHandler}
              checked={props.dataInput.knowTheUsage === 'No'}
            >
              NEE
            </CardInput>
          </Col>
        </Row>
        <Navigation
          dataInput={props.dataInput}
          onClick={props.onClickHandler}
        />
      </Section>
    </Container>
  )
}

export default knowTheUse
