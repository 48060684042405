import React, {Component} from 'react'
import {Row, Col, Container, Modal} from 'react-bootstrap'
import {Link} from 'react-scroll'
import styled from 'styled-components'
import {withRouter} from 'react-router-dom'
import axios from 'axios'
import Cookies from 'js-cookie'
import * as Sentry from '@sentry/browser'

import Button from '../../UI/Button/Button'
import {HeroTitle, Title, SubTitle, BoldTitle} from '../../UI/Title/Title'

import CheckoutForm from '../../../containers/CheckOut/CheckOut'

const Section = styled.section`
  padding-top: 60px;
  padding-bottom: 60px;
`

const ValueAmount = styled.span`
  display: block;
  font-size: ${({fontSize}) => fontSize || '62px'};
  font-weight: bold;
  text-align: ${({align}) => align || 'center'};
  opacity: ${({opacity}) => opacity || '1'};
`

const ChoiceInputCard = styled.div`
  width: auto;
  height: auto;
  background-color: ${({checked}) => (checked ? '#01a384' : '#ffff')};
  color: ${({checked}) => (checked ? '#ffff' : '#212850')};
  border: ${({checked}) => (checked ? 'none' : '1px solid #a8acc1')};
  line-height: 20px;
  font-size: 20px;
  border-radius: 100px;
  cursor: pointer;

  @media only screen and (max-width: 990px) {
    margin-bottom: 20px !important;
  }
`

const CardInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  padding: 1rem;
  transition: transform 300ms ease;
`

const CardLabel = styled.label`
  text-align: center;
  font-size: 1.3rem;
  text-transform: uppercase;
  letter-spacing: 1px;
`

const RadioInput = styled.input.attrs(props => ({
  type: 'radio',
  value: props.value,
  name: 'preference',
}))`
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  margin: 0;
  cursor: pointer;
`

const FullRow = styled(Row)`
  width: 100vw;
  position: relative;
  margin-left: -50vw;
  left: 50%;
  background-color: #fafafc;
  padding-top: 60px;
  padding-bottom: 60px;
`

const LinkButton = styled.button`
  margin: ${({margin}) => margin || 'auto'};
  display: block;
  border: 0;
  padding: 0;
  background-color: transparent;
  color: #01a384;
  font-size: 17px;
  cursor: pointer;
  &:hover {
    color: #00a385;
    cursor: pointer;
    text-decoration: underline;
  }
  &:focus {
    outline: 0 !important;
  }
`
const List = styled.ul`
  padding-left: 0;
`
const ListLine = styled.li`
  font-size: 18px;
  line-height: 35px;
  clear: both;
  padding: 5px;
  margin: 0;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
`

const DivLinebreak = styled.div`
  border-top: 0.5px solid #c6c9d7;
  padding-top: 20px;
  margin-top: 10px;
  padding-bottom: 15px;
`

const ContentWrapper = styled.div`
  padding: ${({padding}) => padding || '0'};

  @media only screen and (max-width: 990px) {
    padding: 0 !important;
  }
`

const Image = styled.img`
  display: block;
  margin: ${({margin}) => margin || '0 auto'};
  width: ${({width}) => width || '100%'};
  height: auto;

  @media only screen and (max-width: 990px) {
    padding-bottom: 30px;
  }
`

const MobileMargin60 = styled.div`
  margin-bottom: 30px;
  margin-top: 30px;
`

class Result extends Component {
  state = {
    inputValues: {
      preference: 'Leasing/Financiering',
    },
    resultValues: {
      estimatedElectricalConsumption: 0,
      coverageByPS: 0,
      fromTheGrid: 0,
      suggestedNumberOfPS: 0,
      increasedInvestmentAllowance: 0,
      totalPsPrice: 0,
      flemishInvestmentWKK: 0,
      gasConsumption: [],
      serviceContract: [],
      waterConsumption: [],
      totalCostsViaGrid: [],
      restOffTheGrid: [],
      benefitOfHeat: [],
      monthlyAmountGrid: [],
      annualSavings: [],
      annualSavingsWithLeasing: [],
      totalCostsViaElugie: [],
      totalCostsViaElugieWithLeasing: [],
      co2Emissions: 0,
      cumulativeAnnualSavings: [],
      cumulativeAnnualSavingsWithLeasing: [],
      estPlacementCost: 0,
      batterySubsidy: 0,
      monthlyLeasing: 0,
      totalPsPriceAfterBenefits: 0,
      typeCustomer: '',
    },
    modal: {
      selfPowerModal: false,
      cumulativePrice: false,
      withElugieModal: false,
      showLeasingDetails: false,
      showCashDetails: false,
      showYearElecDetails: false,
    },
    loading: false,
    processing: false,
  }

  // input or radio
  onChangeHandler = e => {
    const {name, value} = e.target

    this.setState({
      inputValues: {
        ...this.state.inputValues,
        [name]: value,
      },
    })
  }

  handleShow = e => {
    const {name} = e.target

    this.setState(prevState => {
      return {
        modal: {
          ...this.state.modal,
          [name]: !prevState.modal[name],
        },
      }
    })
  }

  handleClose = modalName => {
    this.setState({
      modal: {
        ...this.state.modal,
        [modalName]: false,
      },
    })
  }

  componentDidMount() {
    this.setState({loading: false})
    let simulationId

    /**
     * @param this.props.match.params.simulationId Get simulator id from params.
     * */

    if (
      this.props.history.location.pathname === '/result' &&
      Cookies.get('elugie_sim')
    ) {
      simulationId = Cookies.get('elugie_sim')
      this.props.history.push(`/result/${simulationId}`)
    } else {
      simulationId = this.props.match.params.simulationId
    }
    /**
     * @param response.data.amount_kwh_usage    Elektriciteitsverbruik in kWh
     * @param response.data.suggested_number_of_ps   Aantal elugie centrales
     * @param response.data.coverage_by_ps   Zefstroom met Elugie.
     * @param response.data.from_the_grid   Elektriciteit van het net.
     * @param response.data.co2_emissions   Bedrag minder CO2 uitstoot.
     * @param response.data.est_placement_cost   Bedrag plaatsingskost.
     * @param response.data.flemish_investment_wkk   Bedrag Vlaaamse subsidie.
     * @param response.data.battery_subsidy   Bedrag batterij subsidie.
     * @param response.data.monthly_leasing   Maandelijkse leasekosten.
     * @param response.data.total_ps_price   Elugie centrale totaal prijs.
     * @param response.data.total_ps_price_after_benefits   Elugie centrale totaal prijs na subsidies.
     * @param response.data.increased_investment_allowance   Verhoogde investering aftrek.
     * @param response.data.amount_kwh_cost_grid   Totaal elektriciteitskost uit het net.
     * @param response.data.total_costs_grid   Restafname van het net.
     * @param response.data.monthly_price_elugie  Zelfstroom met Elugie prijs per maand.
     * @param response.data.gas_consumtion   Elugie centrale gasverbruik.
     * @param response.data.service_contract   Elugie centrale service contract kost.
     * @param response.data.water_consumption   Elugie centrale watergebruik kost.
     * @param response.data.benefit_of_heat   Elugie centrale voordeel warmte.
     * @param response.data.annual_savings   Jaarlijkse besparingen.
     * @param response.data.cumulative_annual_savings   Cumulatieve jaarlijkse besparingen.
     */
    axios
      .get(`https://eluapi.com/v1/offers/${simulationId}/`)
      .then(response => {
        const {data} = response

        this.setState({
          resultValues: {
            ...this.state.resultValues,
            estimatedElectricalConsumption: data.amount_kwh_usage,
            suggestedNumberOfPS: data.suggested_number_of_ps * 1,
            coverageByPS: data.coverage_by_ps * 1,
            fromTheGrid: data.from_the_grid * 1,
            co2Emissions: data.co2_emissions * 1,
            estPlacementCost: data.est_placement_cost * 1,
            flemishInvestmentWKK: data.flemish_investment_wkk * 1,
            batterySubsidy: data.battery_subsidy * 1,
            monthlyLeasing: data.monthly_leasing,
            cumulativeAnnualSavings: data.cumulative_annual_savings,
            cumulativeAnnualSavingsWithLeasing:
              data.cumulative_annual_savings_with_leasing,
            totalPsPrice: data.total_ps_price,
            increasedInvestmentAllowance: data.increased_investment_allowance,
            totalPsPriceAfterBenefits: data.total_ps_price_after_benefits,
            totalCostsViaGrid: data.amount_kwh_cost_grid,
            restOffTheGrid: data.total_costs_grid,
            totalCostsViaElugie: data.monthly_price_elugie,
            totalCostsViaElugieWithLeasing:
              data.monthly_price_elugie_with_leasing,
            gasConsumption: data.gas_consumtion,
            serviceContract: data.service_contract,
            waterConsumption: data.water_consumption,
            benefitOfHeat: data.benefit_of_heat,
            annualSavings: data.annual_savings,
            annualSavingsWithLeasing: data.annual_savings_with_leasing,
            typeCustomer: data.type_customer,
          },
        })

        this.setState({loading: false})
      })
      .catch(error => {
        this.setState({loading: false})
        Cookies.remove('elugie_sim', {path: ''})
        Sentry.withScope(scope => {
          Sentry.captureException(error)
        })
        this.props.history.push(`/`)
      })
  }

  downloadPDF = e => {
    let simulationId = this.props.match.params.simulationId
    const {name} = e.target

    if (name === 'downloadAflossingstabel') {
      axios({
        url: `https://eluapi.com/v1/offers/aflossingstabel/${simulationId}/`,
        method: 'GET',
        responseType: 'blob', // important
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'aflossingstabel.pdf')
        document.body.appendChild(link)
        link.click()
      })
    } else if (name === 'downloadDetailBesparing') {
      axios({
        url: `https://eluapi.com/v1/offers/cashtabel/${simulationId}/`,
        method: 'GET',
        responseType: 'blob', // important
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'elek_prijs_met_CHi170.pdf')
        document.body.appendChild(link)
        link.click()
      })
    }
  }
  render() {
    if (this.state.loading) {
      return null
    }

    const {
      suggestedNumberOfPS,
      totalPsPrice,
      co2Emissions,
      fromTheGrid,
      coverageByPS,
      estimatedElectricalConsumption,
      estPlacementCost,
      flemishInvestmentWKK,
      batterySubsidy,
      monthlyLeasing,
      cumulativeAnnualSavings,
      increasedInvestmentAllowance,
      totalPsPriceAfterBenefits,
      totalCostsViaGrid,
      totalCostsViaElugie,
      gasConsumption,
      serviceContract,
      waterConsumption,
      benefitOfHeat,
      restOffTheGrid,
      annualSavings,
    } = this.state.resultValues

    return (
      <Container>
        <Section>
          <Row>
            <Col md={12}>
              <div>
                <HeroTitle>
                  U heeft {suggestedNumberOfPS} Elugie{' '}
                  {suggestedNumberOfPS > 1 ? 'centrales' : 'centrale'} nodig
                </HeroTitle>
                <SubTitle>Dit volgens uw energieverbruik:</SubTitle>
                <BoldTitle>
                  {parseFloat(estimatedElectricalConsumption).toFixed(0)} kWh /
                  jaar
                </BoldTitle>
              </div>
              <br />
              <LinkButton name='kWhModal' onClick={this.handleShow}>
                Meer weten
              </LinkButton>
              <Modal
                onHide={() => this.handleClose('kWhModal')}
                show={this.state.modal.kWhModal}
                size='lg'
                aria-labelledby='contained-modal-title-vcenter'
                centered
              >
                <Modal.Header closeButton />
                <Modal.Body>
                  <Title align='center'>Hoe maken wij de berekening?</Title>
                  <p>
                    1 Elugie centrale kan tot<strong> 13.000kwh </strong>
                    produceren. Een minimum van 10.000kwh per jaar is een
                    noodzaak om een centrale financieel interessant in te
                    zetten. Uw verbruik wordt gedeeld door 13.000kwh en zo wordt
                    het aantal centrales bepaald.
                  </p>
                </Modal.Body>
              </Modal>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <Row>
                <Col xs={6}>
                  <ValueAmount fontSize='40px'>
                    {parseFloat(coverageByPS).toFixed(0)}%
                  </ValueAmount>
                  <SubTitle>Zelfstroom</SubTitle>
                </Col>
                <Col className='border-left' xs={6}>
                  {
                    <ValueAmount fontSize='40px' opacity='0.6'>
                      {parseFloat(fromTheGrid * 100).toFixed(0)}%
                    </ValueAmount>
                  }
                  <SubTitle opacity='0.6'>Van het net</SubTitle>
                </Col>
              </Row>
              <br />
              <LinkButton name='selfPowerModal' onClick={this.handleShow}>
                Meer weten
              </LinkButton>
              <Modal
                onHide={() => this.handleClose('selfPowerModal')}
                show={this.state.modal.selfPowerModal}
                aria-labelledby='contained-modal-title-vcenter'
                size='lg'
                centered
              >
                <Modal.Header closeButton />
                <Modal.Body>
                  <Title align='center'>
                    Hoe werkt onafhankelijkheid bij Elugie?
                  </Title>
                  <p>
                    In uw simulatie wordt{' '}
                    <strong>{parseFloat(coverageByPS).toFixed(0)}% </strong>
                    via uw Elugie centrale geproduceerd en wordt nog{' '}
                    <strong>{parseFloat(fromTheGrid).toFixed(0)}% </strong>
                    uit het net genomen. Dit omdat 1 centrale 13.000kWh per jaar
                    kan produceren en een extra centrale financieel minder
                    interessant is dan de resterende elektriciteit uit het net
                    te nemen. Om 100% dekking te voorzien kan u uiteraard kiezen
                    om een extra centrale te plaatsen. Dankzij het geïntegreerde
                    batterij energie management systeem kan is noodstroom en
                    integratie in de Smart grid van morgen mogelijk en gaat u
                    voor volledige energie vrijheid.
                  </p>
                  <Title align='center'>
                    Elugie Freedom voor volledige energie vrijheid
                  </Title>
                  <p>
                    De Elugie elektriciteitscentrale produceert continu 1,5 kW
                    elektriciteit dankzij duurzame energie opgeslagen en
                    getransporteerd via het bestaande gasnet. Niet gebruikte
                    elektriciteit gaat het net op en een tekort wordt uit het
                    net gehaald. Dit wil zeggen dat gemiddeld tot 80% van de
                    geproduceerde elektriciteit in het gebouw verbruikt wordt en
                    slechts 20% in het net geïnjecteerd wordt om later terug af
                    te nemen. Met de Elugie Freedom uitbreiding kan u teveel
                    geproduceerde elektriciteit stockeren om 100% net
                    onafhankelijk te worden. Wie dagelijks exact kan produceren
                    wat er verbruikt wordt en dit onafhankelijk van beschikbare
                    zon of windenergie, zal de grote winnaar in de
                    energietransitie zijn. Energie vrijheid met Elugie staat
                    voor onafhankelijk en maximaal duurzaam aan een lage en
                    stabiele prijs.
                  </p>

                  <Image
                    alt='elugie-store-energy'
                    src={require('../../../assets/images/store-energy.svg')}
                  />
                  <br />
                  <br />
                  <ol>
                    <li>
                      Continue productie van elektriciteit
                      <strong> via altijd </strong> beschikbare waterstof
                      opgeslagen en getransporteerd via het bestaande gasnet.
                    </li>
                    <li>
                      Uw<strong> dagelijks </strong> verbruik is niet constant
                      maar volgt een bepaald<strong> afnameprofiel </strong>.
                    </li>
                    <li>
                      In perioden dat er minder verbruik is dan productie kan
                      energie opgeslagen worden voor later gebruik
                    </li>
                    <li>
                      In perioden van piekverbruik kan opgeslagen energie
                      aangewend worden, zo wordt maximale energie vrijheid
                      bereikt.
                    </li>
                  </ol>
                </Modal.Body>
              </Modal>
            </Col>
            <Col md={6}>
              <Image
                alt='elugie-power-station'
                src={require('../../../assets/images/ps-2.png')}
                width='66%'
              />
            </Col>
            <Col md={3}>
              <MobileMargin60>
                <ValueAmount fontSize='40px'>{co2Emissions}%</ValueAmount>
                <SubTitle>Minder CO₂ uitstoot</SubTitle>
                <br />
                <LinkButton name='CO2Modal' onClick={this.handleShow}>
                  Meer weten
                </LinkButton>
                <Modal
                  onHide={() => this.handleClose('CO2Modal')}
                  show={this.state.modal.CO2Modal}
                  size='lg'
                  aria-labelledby='contained-modal-title-vcenter'
                  centered
                >
                  <Modal.Header closeButton />
                  <Modal.Body>
                    <Title align='center'>
                      Hoe werkt duurzaamheid bij Elugie?
                    </Title>
                    <p>
                      De waterstof technologie van Elugie werkt op een zeer hoge
                      temperatuur nl. 750 graden wat het toelaat om zonder
                      energie toevoeging waterstof uit het gas te halen.
                      Hierdoor worden de hoogste elektrische rendement behaald
                      wereldwijd. Er wordt niks verbrand, de stroomopwekking
                      gebeurd door een chemische reactie tussen zuurstof en
                      waterstof waarbij met zeer hoog rendement stroom vrijkomt.
                      Bij gebruik van aardgas(fossiel gas) wordt een CO2
                      reductie van 20 tot 50% bereikt ten opzichte van
                      elektriciteit uit het stroomnet. Door gebruik te maken van
                      groengas met garantie van oorsprong afkomstig van zon of
                      windenergie projecten, biomethaan van landbouw afval
                      projecten of syngas uit biomassa projecten wordt uw CO2
                      uitstoot gereduceerd naar bijna 100%. Opslag van alle
                      hernieuwbare vormen in gasvorm heeft het ongekende
                      voordeel dat op deze manier duurzame energie 1000den keren
                      gemakkelijker en goedkoper kan opgeslagen worden dan
                      energie via het elektriciteitsnet. De weg naar 100%
                      duurzaam voor elektriciteit kan via Elugie vandaag al
                      bewandeld worden. Geen compensatie met groene wind of
                      zon-energie en in de winter alsnog van de nucleaire,
                      steenkool of gascentrales verbruiken, neen wij gaan voor
                      duurzame stroom elke seconde van de dag volgens ons
                      verbruiksprofiel. U toch ook?
                    </p>

                    <Title align='center'>
                      Hoe werd uw CO2 besparing berekend?
                    </Title>
                    <p>
                      Met aardgas kan u vandaag tot 20% CO2 besparen met Elugie,
                      na de kernuitstap tot 50% en met groengas vandaag al tot
                      97% t.o.v. elektriciteit uit het stroomnet.
                    </p>
                  </Modal.Body>
                </Modal>
              </MobileMargin60>
            </Col>
          </Row>
        </Section>
        <Section>
          <Row
            style={{
              backgroundColor: '#fafafc',
              padding: '60px 0',
              alignItems: 'center',
            }}
          >
            <Col md={12}>
              <div>
                <Title align='center'>
                  Elugie leidt de Belgische waterstofrevolutie
                </Title>
              </div>
            </Col>
            <Col md={3}>
              <Image
                alt='happy-map'
                src={require('../../../assets/images/icon-happy-map.svg')}
                margin='none'
              />
            </Col>
            <Col md={3}>
              <div>
                <SubTitle fontSize='16px' opacity='0.5'>
                  ELUGIE CENTRALES
                </SubTitle>
                <ValueAmount>350+</ValueAmount>
              </div>
            </Col>
            <Col md={3}>
              <div>
                <SubTitle fontSize='16px' opacity='0.5'>
                  HUIDIGE CAPACITEIT IN BELGIË
                </SubTitle>
                <ValueAmount>
                  525<sub>kW</sub>
                </ValueAmount>
              </div>
            </Col>
            <Col md={3}>
              <div>
                <SubTitle fontSize='16px' opacity='0.5'>
                  CO2 BESPAARD
                </SubTitle>
                <ValueAmount>
                  3.826<sub>ton</sub>
                </ValueAmount>
              </div>
            </Col>
          </Row>
        </Section>
        <Section>
          <Row style={{margin: '60px 0'}}>
            <Col md={6}>
              <div>
                {this.state.resultValues.typeCustomer !== 'KMO' ? (
                  <Image
                    alt='Lode Fastré'
                    src={require('../../../assets/images/lode.jpg')}
                  />
                ) : (
                  <Image
                    alt='Roel Van Hove'
                    src={require('../../../assets/images/Roel-Van-Hove.jpg')}
                  />
                )}
              </div>
            </Col>
            <Col style={{alignItems: 'center', display: 'flex'}} md={6}>
              <ContentWrapper padding='0 0 0 20px'>
                <Title>Waterstof: 100% duurzame energie</Title>
                <p>
                  De Elugie waterstof elektriciteitscentrale maakt ter plaatse
                  elektriciteit uit water en groengas. Gas uit het net wordt
                  gekraakt tot waterstof, die op zijn beurt wordt gebruikt om
                  elektriciteit op te wekken.
                </p>
                <p>
                  Het eindresultaat is duurzame zelfstroom, onafhankelijkheid
                  van prijsstijgingen op het stroomnet, en een veel efficiëntere
                  manier van energieproductie (die 100% duurzaam kan zijn).
                </p>
                <Row>
                  <Col md={12}>
                    {this.state.resultValues.typeCustomer !== 'KMO' ? (
                      <span style={{fontStyle: 'italic'}}>
                        “De restwarmte die we inzetten voor ons sanitair warm
                        water, is een mooie extra. Ik heb als boekhouder al
                        meerdere van mijn eigen klanten doorgestuurd naar
                        Elugie!”
                      </span>
                    ) : (
                      <span style={{fontStyle: 'italic'}}>
                        “De twee volgende Elugie-centrales moesten naar ons toe
                        komen.”
                      </span>
                    )}
                  </Col>
                  <Col style={{marginTop: '10px'}} md={12}>
                    {this.state.resultValues.typeCustomer !== 'KMO' ? (
                      <BoldTitle align='left' style={{fontStyle: 'bold'}}>
                        Lode Fastré
                      </BoldTitle>
                    ) : (
                      <BoldTitle align='left' style={{fontStyle: 'bold'}}>
                        Roel Van Hove, BIO-Shop Schilde
                      </BoldTitle>
                    )}
                  </Col>
                </Row>
              </ContentWrapper>
            </Col>
          </Row>
          <Row style={{margin: '60px 0'}}>
            <Col md={{size: 6, order: 12}}>
              <div>
                {this.state.resultValues.typeCustomer !== 'KMO' ? (
                  <Image
                    alt='Dimitri De Donder Immo Optima'
                    style={{
                      width: '100%',
                    }}
                    src={require('../../../assets/images/dimitri-de-donder.jpg')}
                  />
                ) : (
                  <Image
                    alt='Brouwerij VBDCK-KEREL'
                    style={{
                      width: '100%',
                    }}
                    src={require('../../../assets/images/VBDCK-001-1.jpg')}
                  />
                )}
              </div>
            </Col>
            <Col
              style={{alignItems: 'center', display: 'flex'}}
              md={{size: 6, order: 1}}
            >
              {this.state.resultValues.typeCustomer !== 'KMO' ? (
                <ContentWrapper padding='0 20px 0 0 '>
                  <Title>Volledig onafhankelijk, volledig autonoom</Title>
                  <p>
                    Zelfstroom opwekken betekent onafhankelijkheid: vrijheid van
                    de stijgende stroomprijzen, autonoom uw elektrische wagens
                    opladen, en zekerheid over uw elektriciteitsvoorziening —
                    stil en zorgeloos, elk moment van de dag.
                  </p>
                  <p>
                    Energie uit waterstof is stil, compact en helemaal schoon:
                    de enige uitstoot is waterdamp, en de restwarmte die u
                    produceert is direct zinnig toepasbaar in uw vloerverwarming
                    of andere warmtebronnen.
                  </p>
                  <Row>
                    <Col md={12}>
                      <span style={{fontStyle: 'italic'}}>
                        “Er zijn al verschillende collega’s komen kijken naar
                        onze installatie. Eén van mijn beste vrienden heeft
                        ondertussen dezelfde Elugie centrale gezet.”
                      </span>
                    </Col>
                    <Col style={{marginTop: '10px'}} md={12}>
                      <BoldTitle align='left' style={{fontStyle: 'bold'}}>
                        Dimitri De Donder, Immo Optima
                      </BoldTitle>
                    </Col>
                  </Row>
                </ContentWrapper>
              ) : (
                <ContentWrapper padding='0 20px 0 0 '>
                  <Title>Groeiende besparingen en een hoog rendement</Title>
                  <p>
                    Naast een duidelijke positieve impact op het milieu, zorgt
                    uw eigen Elugie centrale ook voor aanzienlijke besparingen.
                  </p>
                  <p>
                    De leasing of aankoop van de centrale wordt betaald met de
                    besparing die wordt gerealiseerd en op een periode van
                    15jaar bespaar je meer dan 30% op kosten voor elektriciteit.
                    Als u uw factuur blijft betalen ben je je centen kwijt.
                  </p>
                  <Row>
                    <Col md={12}>
                      <span style={{fontStyle: 'italic'}}>
                        “Zelfs de restwarmte van de centrales gebruiken we in
                        ons brouwproces”
                      </span>
                    </Col>
                    <Col style={{marginTop: '10px'}} md={12}>
                      <BoldTitle align='left' style={{fontStyle: 'bold'}}>
                        Philippe De Cock, Brouwerij VBDCK-KEREL
                      </BoldTitle>
                    </Col>
                  </Row>
                </ContentWrapper>
              )}
            </Col>
          </Row>
          <Row style={{margin: '60px 0'}}>
            <Col md={6}>
              <div>
                {this.state.resultValues.typeCustomer !== 'KMO' ? (
                  <Image
                    alt='Dirk Vandenbergh'
                    style={{
                      width: '100%',
                    }}
                    src={require('../../../assets/images/dirk-vandenbergh.jpg')}
                  />
                ) : (
                  <Image
                    alt='Jeroen Anneleen'
                    style={{
                      width: '100%',
                    }}
                    src={require('../../../assets/images/JeroenAnneleen.jpg')}
                  />
                )}
              </div>
            </Col>
            <Col style={{alignItems: 'center', display: 'flex'}} md={6}>
              {this.state.resultValues.typeCustomer !== 'KMO' ? (
                <ContentWrapper padding='0 0 0 20px '>
                  <Title>Besparingen op uw energierekening</Title>
                  <p>
                    De Belgische kernuitstap drijft stroomprijzen op, en
                    toenemend gebruik van duurzame bronnen zet het stroomnet
                    verder onder druk. Het resultaat is — al jarenlang —
                    stijgende stroomprijzen. Een zelfstroom centrale verzekert u
                    van een goedkope energievoorziening, in eigen beheer.
                  </p>
                  <p>
                    Ontkoppeling van stroomprijzen is vooral interessant voor
                    gebruikers met bijvoorbeeld een elektrisch wagenpark, een
                    warmtepomp of een anderszins bovengemiddeld energiegebruik.
                  </p>
                  <Row>
                    <Col md={12}>
                      <span style={{fontStyle: 'italic'}}>
                        “Juist afrekening gehad van elektriciteit en vlotjes 67%
                        minder verbruik dan vorig jaar! Dus supertevreden klant!
                        Ik kan alleen maar merci zeggen aan gans uw equipe toffe
                        gasten!”
                      </span>
                    </Col>
                    <Col style={{marginTop: '10px'}} md={12}>
                      <BoldTitle align='left' style={{fontStyle: 'bold'}}>
                        Dirk Vandenbergh
                      </BoldTitle>
                    </Col>
                  </Row>
                </ContentWrapper>
              ) : (
                <ContentWrapper padding='0 0 0 20px '>
                  <Title>Volledige onafhankelijkheid</Title>
                  <p>
                    Uw Elugie waterstofcentrale komt met een stevige set
                    batterijen. Hiermee slaat u overschotten voor de korte
                    termijn op, zodat u ook op dure piekuren niet uit het net
                    hoeft te tappen.
                  </p>
                  <p>
                    Met de invoering van smart meters en nieuwe regelgeving in
                    2021 wordt elke gebruiker beloond om het stroomnet minimaal
                    te belasten. Met een Elugie centrale bent u volledig
                    onafhankelijk, en ondersteunt u deze duurzame revolutie.
                  </p>
                  <Row>
                    <Col md={12}>
                      <span style={{fontStyle: 'italic'}}>
                        “Stijgende energieprijzen wegen niet meer op de toekomst
                        van onze zaak.”
                      </span>
                    </Col>
                    <Col style={{marginTop: '10px'}} md={12}>
                      <BoldTitle align='left' style={{fontStyle: 'bold'}}>
                        Slagerij Jeroen en Anneleen-Oudenburg
                      </BoldTitle>
                    </Col>
                  </Row>
                </ContentWrapper>
              )}
            </Col>
          </Row>
        </Section>
        <Section>
          <Row>
            <Col md={12}>
              <div>
                <Title align='center'>Technische specificaties</Title>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div
                style={{
                  marginTop: '20px',
                  marginButton: '20px',
                  width: '100%',
                }}
              >
                <Image
                  alt='techspec-power-station'
                  src={require('../../../assets/images/techspec-power-station.png')}
                />
              </div>
            </Col>
            <Col style={{alignItems: 'center', display: 'flex'}} md={3}>
              <div>
                <p>
                  <span style={{color: '#A8ACC2'}}>Elektrische energie:</span>
                  <br />
                  13.000 kWh
                </p>
                <p>
                  <span style={{color: '#A8ACC2'}}>Thermische energie:</span>
                  <br />
                  tot 6.500 kWh
                </p>
                <p>
                  <span style={{color: '#A8ACC2'}}>Productie-uren:</span>
                  <br />
                  8.700 uren, hele jaar door
                </p>
                <p>
                  <span style={{color: '#A8ACC2'}}>Verbruik gas:</span>
                  <br />
                  22.000 kWh (bij start)
                </p>
                <p>
                  <span style={{color: '#A8ACC2'}}>Waterstoftechnologie:</span>
                  <br />
                  SOFC
                </p>
              </div>
            </Col>
            <Col style={{alignItems: 'center', display: 'flex'}} md={3}>
              <div className='wpb_wrapper' style={{marginTop: '20%'}}>
                <p>
                  <span style={{color: '#A8ACC2'}}>
                    Elektrische efficiëntie:
                  </span>
                  <br />
                  +60%
                </p>
                <p>
                  <span style={{color: '#A8ACC2'}}>
                    Thermische efficientie:
                  </span>
                  <br />
                  25-32%
                </p>
                <p>
                  <span style={{color: '#A8ACC2'}}>Gewicht:</span>
                  <br />
                  265kg
                </p>
                <p>
                  <span style={{color: '#A8ACC2'}}>Capaciteit/opslag:</span>
                  <br />
                  5kW-45kW ; 14kwh-72kWh
                </p>
                <p>
                  <span style={{color: '#A8ACC2'}}>My Elugie:</span>
                  <br />
                  Monitoring, energie management, groepsaankoop groengas
                </p>
              </div>
            </Col>
          </Row>
        </Section>
        <Section>
          <FullRow>
            <Container>
              <Col md={12}>
                <Section>
                  <Title align='center'>
                    Elugie prijsberekening en besparing
                  </Title>
                  <SubTitle>
                    Kies voor cash aankoop, of plaats uw centrales via leasing
                  </SubTitle>
                  <Row style={{padding: '40px 0'}}>
                    <Col md={6}>
                      <ChoiceInputCard
                        checked={
                          this.state.inputValues.preference ===
                          'Leasing/Financiering'
                        }
                      >
                        <RadioInput
                          value='Leasing/Financiering'
                          onChange={this.onChangeHandler}
                          checked={
                            this.state.inputValues.preference ===
                            'Leasing/Financiering'
                          }
                        />
                        <CardInputContainer>
                          <CardLabel>
                            {this.state.resultValues.typeCustomer !==
                            'Particulier'
                              ? 'Leasing'
                              : 'Financiering'}
                          </CardLabel>
                          <span>Overstappen zonder investering</span>
                        </CardInputContainer>
                      </ChoiceInputCard>
                    </Col>
                    <Col md={6}>
                      <ChoiceInputCard
                        checked={this.state.inputValues.preference === 'Cash'}
                      >
                        <RadioInput
                          value='Cash'
                          onChange={this.onChangeHandler}
                        />
                        <CardInputContainer>
                          <CardLabel>Cash</CardLabel>
                          <span>Investeer in uw zaak</span>
                        </CardInputContainer>
                      </ChoiceInputCard>
                    </Col>
                  </Row>
                  {this.state.inputValues.preference === 'Cash' ? (
                    <div>
                      <List>
                        {this.state.modal.showCashDetails ? (
                          <>
                            <ListLine>
                              <span>
                                {suggestedNumberOfPS} x Elugie centrale(s)
                              </span>
                              <span>
                                {new Intl.NumberFormat('nl-BE', {
                                  style: 'currency',
                                  currency: 'EUR',
                                }).format(totalPsPrice)}
                              </span>
                            </ListLine>
                            <ListLine>
                              <span>Plaatsingskost</span>
                              <span>
                                {new Intl.NumberFormat('nl-BE', {
                                  style: 'currency',
                                  currency: 'EUR',
                                }).format(Math.abs(estPlacementCost))}
                              </span>
                            </ListLine>
                            <ListLine>
                              <span>
                                Vlaamse subsidies voor waterstof technologie
                              </span>
                              <span>
                                -{' '}
                                {new Intl.NumberFormat('nl-BE', {
                                  style: 'currency',
                                  currency: 'EUR',
                                }).format(Math.abs(flemishInvestmentWKK))}
                              </span>
                            </ListLine>
                            <ListLine>
                              <span>Vlaamse subsidies voor batterijen</span>
                              <span>
                                -{' '}
                                {new Intl.NumberFormat('nl-BE', {
                                  style: 'currency',
                                  currency: 'EUR',
                                }).format(Math.abs(batterySubsidy))}
                              </span>
                            </ListLine>
                            {this.state.resultValues.typeCustomer !==
                            'Particulier' ? (
                              <ListLine>
                                <span>Verhoogde investering aftrek</span>
                                <span>
                                  -{' '}
                                  {new Intl.NumberFormat('nl-BE', {
                                    style: 'currency',
                                    currency: 'EUR',
                                  }).format(
                                    Math.abs(increasedInvestmentAllowance),
                                  )}
                                </span>
                              </ListLine>
                            ) : null}
                          </>
                        ) : null}
                        <ListLine>
                          <BoldTitle align='left'>
                            Totaal prijs na subsidies{' '}
                            {this.state.resultValues.typeCustomer !==
                            'Particulier'
                              ? '(excl. BTW)'
                              : '(incl. BTW)'}{' '}
                          </BoldTitle>
                          <BoldTitle>
                            {new Intl.NumberFormat('nl-BE', {
                              style: 'currency',
                              currency: 'EUR',
                            }).format(totalPsPriceAfterBenefits)}
                          </BoldTitle>
                        </ListLine>
                        <ListLine>
                          <LinkButton
                            margin='none'
                            name='showCashDetails'
                            onClick={this.handleShow}
                          >
                            {this.state.modal.showCashDetails
                              ? ' - Verberg details'
                              : ' + Meer details'}
                          </LinkButton>
                        </ListLine>
                      </List>
                    </div>
                  ) : (
                    <>
                      <div>
                        <List>
                          {this.state.modal.showLeasingDetails ? (
                            <>
                              <ListLine>
                                <span>Plaatsingskost</span>
                                <span>
                                  {new Intl.NumberFormat('nl-BE', {
                                    style: 'currency',
                                    currency: 'EUR',
                                  }).format(estPlacementCost)}
                                </span>
                              </ListLine>
                              <ListLine>
                                <span>
                                  Vlaamse subsidies voor waterstof technologie
                                </span>
                                <span>
                                  -{' '}
                                  {new Intl.NumberFormat('nl-BE', {
                                    style: 'currency',
                                    currency: 'EUR',
                                  }).format(Math.abs(flemishInvestmentWKK))}
                                </span>
                              </ListLine>
                              <ListLine>
                                <span>Vlaamse subsidies voor batterijen</span>
                                <span>
                                  -{' '}
                                  {new Intl.NumberFormat('nl-BE', {
                                    style: 'currency',
                                    currency: 'EUR',
                                  }).format(Math.abs(batterySubsidy))}
                                </span>
                              </ListLine>
                            </>
                          ) : null}

                          <ListLine>
                            <BoldTitle align='left'>
                              {this.state.resultValues.typeCustomer !==
                              'Particulier'
                                ? 'Eigen inbreng plaatsingskost na subsidies (excl.BTW)'
                                : 'Totaal prijs na subsidies (incl. BTW)'}
                            </BoldTitle>
                            <BoldTitle align='left'>
                              {this.state.resultValues.typeCustomer !==
                              'Particulier'
                                ? new Intl.NumberFormat('nl-BE', {
                                    style: 'currency',
                                    currency: 'EUR',
                                  }).format(
                                    Math.abs(
                                      estPlacementCost +
                                        flemishInvestmentWKK +
                                        batterySubsidy,
                                    ),
                                  )
                                : new Intl.NumberFormat('nl-BE', {
                                    style: 'currency',
                                    currency: 'EUR',
                                  }).format(totalPsPriceAfterBenefits)}
                            </BoldTitle>
                          </ListLine>
                          {this.state.resultValues.typeCustomer !==
                          'Particulier' ? (
                            <ListLine>
                              <LinkButton
                                margin='none'
                                name='showLeasingDetails'
                                onClick={this.handleShow}
                              >
                                {this.state.modal.showLeasingDetails
                                  ? ' - Verberg details'
                                  : ' + Meer details'}
                              </LinkButton>
                            </ListLine>
                          ) : null}
                        </List>
                      </div>
                      <DivLinebreak>
                        <List>
                          <ListLine>
                            <BoldTitle align='left'>
                              {this.state.resultValues.typeCustomer !==
                              'Particulier'
                                ? 'Uw leasingkost'
                                : 'Uw financieringskost'}{' '}
                              voor {suggestedNumberOfPS} Elugie centrales
                            </BoldTitle>
                            <BoldTitle align='left'>
                              {new Intl.NumberFormat('nl-BE', {
                                style: 'currency',
                                currency: 'EUR',
                              }).format(monthlyLeasing[0])}
                            </BoldTitle>
                          </ListLine>
                          <ListLine>
                            <SubTitle opacity='0.5' marginBottom='0'>
                              120 maanden,{' '}
                              {this.state.resultValues.typeCustomer !==
                              'Particulier'
                                ? ' 3,65% excl. btw'
                                : '1,35% incl. btw'}
                              ,
                            </SubTitle>
                          </ListLine>
                          <ListLine>
                            <BoldTitle align='left'>
                              <LinkButton
                                margin='none'
                                name='downloadAflossingstabel'
                                onClick={this.downloadPDF}
                              >
                                Download aflossingstabel{' '}
                                {this.state.resultValues.typeCustomer !==
                                'Particulier'
                                  ? 'leasing'
                                  : 'financiering'}
                              </LinkButton>
                            </BoldTitle>
                          </ListLine>
                        </List>
                      </DivLinebreak>
                    </>
                  )}
                  <DivLinebreak>
                    <List>
                      <ListLine>
                        <BoldTitle align='left'>
                          Uw bruto besparing op 20 jaar met Elugie t.o.v.
                          elektriciteit uit het net
                        </BoldTitle>
                        <BoldTitle align='left'>
                          {new Intl.NumberFormat('nl-BE', {
                            style: 'currency',
                            currency: 'EUR',
                          }).format(Math.abs(cumulativeAnnualSavings[19]))}
                        </BoldTitle>
                      </ListLine>
                      <ListLine>
                        <LinkButton
                          margin='none'
                          name='showYearElecDetails'
                          onClick={this.handleShow}
                        >
                          Meer weten
                        </LinkButton>
                        <Modal
                          onHide={() => this.handleClose('showYearElecDetails')}
                          show={this.state.modal.showYearElecDetails}
                          size='lg'
                          aria-labelledby='contained-modal-title-vcenter'
                          centered
                        >
                          <Modal.Header closeButton />
                          <Modal.Body>
                            <Title align='center'>
                              Hoe werd uw besparing berekend?
                            </Title>
                            <SubTitle>
                              Zelfstroom met Elugie t.o.v. elektriciteit uit het
                              stroomnet
                            </SubTitle>
                            <br />
                            <div style={{overflowX: 'auto'}}>
                              <table
                                style={{width: '100%', textAlign: 'right'}}
                              >
                                <tbody>
                                  <tr>
                                    <td></td>
                                    <td>
                                      <BoldTitle align='right'>
                                        5de jaar
                                      </BoldTitle>
                                    </td>
                                    <td>
                                      <BoldTitle align='right'>
                                        10de jaar
                                      </BoldTitle>
                                    </td>
                                    <td>
                                      <BoldTitle align='right'>
                                        15de jaar
                                      </BoldTitle>
                                    </td>
                                    <td>
                                      <BoldTitle align='right'>
                                        20ste jaar
                                      </BoldTitle>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>&nbsp;</td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <BoldTitle align='left' opacity='0.6'>
                                        Elektriciteit uit het net: <sup>1</sup>
                                      </BoldTitle>
                                    </td>
                                    <td>
                                      <BoldTitle align='right' opacity='0.6'>
                                        {new Intl.NumberFormat('nl-BE', {
                                          style: 'currency',
                                          currency: 'EUR',
                                        }).format(
                                          Math.abs(totalCostsViaGrid[4]),
                                        )}
                                      </BoldTitle>
                                    </td>
                                    <td>
                                      <BoldTitle align='right' opacity='0.6'>
                                        {new Intl.NumberFormat('nl-BE', {
                                          style: 'currency',
                                          currency: 'EUR',
                                        }).format(
                                          Math.abs(totalCostsViaGrid[9]),
                                        )}
                                      </BoldTitle>
                                    </td>
                                    <td>
                                      <BoldTitle align='right' opacity='0.6'>
                                        {new Intl.NumberFormat('nl-BE', {
                                          style: 'currency',
                                          currency: 'EUR',
                                        }).format(
                                          Math.abs(totalCostsViaGrid[14]),
                                        )}
                                      </BoldTitle>
                                    </td>
                                    <td>
                                      <BoldTitle align='right' opacity='0.6'>
                                        {new Intl.NumberFormat('nl-BE', {
                                          style: 'currency',
                                          currency: 'EUR',
                                        }).format(
                                          Math.abs(totalCostsViaGrid[19]),
                                        )}
                                      </BoldTitle>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>&nbsp;</td>
                                  </tr>
                                  <tr>
                                    <td style={{textAlign: 'left'}}>
                                      <BoldTitle align='left'>
                                        Zelfstroom met Elugie:
                                      </BoldTitle>
                                    </td>
                                    <td>
                                      <BoldTitle align='right'>
                                        {new Intl.NumberFormat('nl-BE', {
                                          style: 'currency',
                                          currency: 'EUR',
                                        }).format(
                                          Math.abs(totalCostsViaElugie[4] * 12),
                                        )}
                                      </BoldTitle>
                                    </td>
                                    <td>
                                      <BoldTitle align='right'>
                                        {new Intl.NumberFormat('nl-BE', {
                                          style: 'currency',
                                          currency: 'EUR',
                                        }).format(
                                          Math.abs(totalCostsViaElugie[9] * 12),
                                        )}
                                      </BoldTitle>
                                    </td>
                                    <td>
                                      <BoldTitle align='right'>
                                        {new Intl.NumberFormat('nl-BE', {
                                          style: 'currency',
                                          currency: 'EUR',
                                        }).format(
                                          Math.abs(
                                            totalCostsViaElugie[14] * 12,
                                          ),
                                        )}
                                      </BoldTitle>
                                    </td>
                                    <td>
                                      <BoldTitle align='right'>
                                        {new Intl.NumberFormat('nl-BE', {
                                          style: 'currency',
                                          currency: 'EUR',
                                        }).format(
                                          Math.abs(
                                            totalCostsViaElugie[19] * 12,
                                          ),
                                        )}
                                      </BoldTitle>
                                    </td>
                                  </tr>
                                  <tr style={{fontSize: '15px'}}>
                                    <td style={{textAlign: 'left'}}>
                                      - Gasgebruik <sup>2</sup>
                                    </td>
                                    <td>
                                      {new Intl.NumberFormat('nl-BE', {
                                        style: 'currency',
                                        currency: 'EUR',
                                      }).format(
                                        Math.abs(gasConsumption[5] * 12),
                                      )}
                                    </td>
                                    <td>
                                      {new Intl.NumberFormat('nl-BE', {
                                        style: 'currency',
                                        currency: 'EUR',
                                      }).format(
                                        Math.abs(gasConsumption[10] * 12),
                                      )}
                                    </td>
                                    <td>
                                      {new Intl.NumberFormat('nl-BE', {
                                        style: 'currency',
                                        currency: 'EUR',
                                      }).format(
                                        Math.abs(gasConsumption[15] * 12),
                                      )}
                                    </td>
                                    <td>
                                      {new Intl.NumberFormat('nl-BE', {
                                        style: 'currency',
                                        currency: 'EUR',
                                      }).format(
                                        Math.abs(gasConsumption[20] * 12),
                                      )}
                                    </td>
                                  </tr>
                                  <tr style={{fontSize: '15px'}}>
                                    <td style={{textAlign: 'left'}}>
                                      - Restafname van het net
                                    </td>
                                    <td>
                                      {new Intl.NumberFormat('nl-BE', {
                                        style: 'currency',
                                        currency: 'EUR',
                                      }).format(
                                        Math.abs(restOffTheGrid[5] * 12),
                                      )}
                                    </td>
                                    <td>
                                      {new Intl.NumberFormat('nl-BE', {
                                        style: 'currency',
                                        currency: 'EUR',
                                      }).format(
                                        Math.abs(restOffTheGrid[10] * 12),
                                      )}
                                    </td>
                                    <td>
                                      {new Intl.NumberFormat('nl-BE', {
                                        style: 'currency',
                                        currency: 'EUR',
                                      }).format(
                                        Math.abs(restOffTheGrid[15] * 12),
                                      )}
                                    </td>
                                    <td>
                                      {new Intl.NumberFormat('nl-BE', {
                                        style: 'currency',
                                        currency: 'EUR',
                                      }).format(
                                        Math.abs(restOffTheGrid[20] * 12),
                                      )}
                                    </td>
                                  </tr>
                                  <tr style={{fontSize: '15px'}}>
                                    <td style={{textAlign: 'left'}}>
                                      - Servicecontract <sup>3</sup>
                                    </td>
                                    <td>
                                      {new Intl.NumberFormat('nl-BE', {
                                        style: 'currency',
                                        currency: 'EUR',
                                      }).format(
                                        Math.abs(serviceContract[5] * 12),
                                      )}
                                    </td>
                                    <td>
                                      {new Intl.NumberFormat('nl-BE', {
                                        style: 'currency',
                                        currency: 'EUR',
                                      }).format(
                                        Math.abs(serviceContract[10] * 12),
                                      )}
                                    </td>
                                    <td>
                                      {new Intl.NumberFormat('nl-BE', {
                                        style: 'currency',
                                        currency: 'EUR',
                                      }).format(
                                        Math.abs(serviceContract[15] * 12),
                                      )}
                                    </td>
                                    <td>
                                      {new Intl.NumberFormat('nl-BE', {
                                        style: 'currency',
                                        currency: 'EUR',
                                      }).format(
                                        Math.abs(serviceContract[20] * 12),
                                      )}
                                    </td>
                                  </tr>
                                  <tr style={{fontSize: '15px'}}>
                                    <td style={{textAlign: 'left'}}>
                                      - Waterverbuik <sup>3</sup>
                                    </td>
                                    <td>
                                      {new Intl.NumberFormat('nl-BE', {
                                        style: 'currency',
                                        currency: 'EUR',
                                      }).format(
                                        Math.abs(waterConsumption[5] * 12),
                                      )}
                                    </td>
                                    <td>
                                      {new Intl.NumberFormat('nl-BE', {
                                        style: 'currency',
                                        currency: 'EUR',
                                      }).format(
                                        Math.abs(waterConsumption[10] * 12),
                                      )}
                                    </td>
                                    <td>
                                      {new Intl.NumberFormat('nl-BE', {
                                        style: 'currency',
                                        currency: 'EUR',
                                      }).format(
                                        Math.abs(waterConsumption[15] * 12),
                                      )}
                                    </td>
                                    <td>
                                      {new Intl.NumberFormat('nl-BE', {
                                        style: 'currency',
                                        currency: 'EUR',
                                      }).format(
                                        Math.abs(waterConsumption[20] * 12),
                                      )}
                                    </td>
                                  </tr>
                                  <tr style={{fontSize: '15px'}}>
                                    <td style={{textAlign: 'left'}}>
                                      - Voordeel warmte
                                    </td>
                                    <td>
                                      {new Intl.NumberFormat('nl-BE', {
                                        style: 'currency',
                                        currency: 'EUR',
                                      }).format(
                                        Math.abs(benefitOfHeat[5] * 12),
                                      )}
                                    </td>
                                    <td>
                                      {new Intl.NumberFormat('nl-BE', {
                                        style: 'currency',
                                        currency: 'EUR',
                                      }).format(
                                        Math.abs(benefitOfHeat[10] * 12),
                                      )}
                                    </td>
                                    <td>
                                      {new Intl.NumberFormat('nl-BE', {
                                        style: 'currency',
                                        currency: 'EUR',
                                      }).format(
                                        Math.abs(benefitOfHeat[15] * 12),
                                      )}
                                    </td>
                                    <td>
                                      {new Intl.NumberFormat('nl-BE', {
                                        style: 'currency',
                                        currency: 'EUR',
                                      }).format(
                                        Math.abs(benefitOfHeat[20] * 12),
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>&nbsp;</td>
                                  </tr>
                                  <tr>
                                    <td style={{textAlign: 'left'}}>
                                      <BoldTitle align='left' color='#00A385'>
                                        Jaarlijks besparing
                                      </BoldTitle>
                                    </td>
                                    <td>
                                      <BoldTitle align='right' color='#00A385'>
                                        {new Intl.NumberFormat('nl-BE', {
                                          style: 'currency',
                                          currency: 'EUR',
                                        }).format(Math.abs(annualSavings[4]))}
                                      </BoldTitle>
                                    </td>
                                    <td>
                                      <BoldTitle align='right' color='#00A385'>
                                        {new Intl.NumberFormat('nl-BE', {
                                          style: 'currency',
                                          currency: 'EUR',
                                        }).format(Math.abs(annualSavings[9]))}
                                      </BoldTitle>
                                    </td>
                                    <td>
                                      <BoldTitle align='right' color='#00A385'>
                                        {new Intl.NumberFormat('nl-BE', {
                                          style: 'currency',
                                          currency: 'EUR',
                                        }).format(Math.abs(annualSavings[14]))}
                                      </BoldTitle>
                                    </td>
                                    <td>
                                      <BoldTitle align='right' color='#00A385'>
                                        {new Intl.NumberFormat('nl-BE', {
                                          style: 'currency',
                                          currency: 'EUR',
                                        }).format(Math.abs(annualSavings[19]))}
                                      </BoldTitle>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style={{textAlign: 'left'}}>
                                      <BoldTitle align='left' color='#00A385'>
                                        Cumul besparing
                                      </BoldTitle>
                                    </td>
                                    <td>
                                      <BoldTitle align='right' color='#00A385'>
                                        {new Intl.NumberFormat('nl-BE', {
                                          style: 'currency',
                                          currency: 'EUR',
                                        }).format(
                                          Math.abs(cumulativeAnnualSavings[4]),
                                        )}
                                      </BoldTitle>
                                    </td>
                                    <td>
                                      <BoldTitle align='right' color='#00A385'>
                                        {new Intl.NumberFormat('nl-BE', {
                                          style: 'currency',
                                          currency: 'EUR',
                                        }).format(
                                          Math.abs(cumulativeAnnualSavings[9]),
                                        )}
                                      </BoldTitle>
                                    </td>
                                    <td>
                                      <BoldTitle align='right' color='#00A385'>
                                        {new Intl.NumberFormat('nl-BE', {
                                          style: 'currency',
                                          currency: 'EUR',
                                        }).format(
                                          Math.abs(cumulativeAnnualSavings[14]),
                                        )}
                                      </BoldTitle>
                                    </td>
                                    <td>
                                      <BoldTitle align='right' color='#00A385'>
                                        {new Intl.NumberFormat('nl-BE', {
                                          style: 'currency',
                                          currency: 'EUR',
                                        }).format(
                                          Math.abs(cumulativeAnnualSavings[19]),
                                        )}
                                      </BoldTitle>
                                    </td>
                                    <td></td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <br />
                            <br />
                            <div>
                              <SubTitle
                                fontSize='14px'
                                align='left'
                                opacity='0.5'
                              >
                                1. De jaarlijkse kosten voor elektriciteit via
                                het net (incl. netbeheerkosten etc.) stijgen de
                                eerste 10 jaar met 6% en vervolgens met 5% per
                                jaar. De jaarlijkse elektriciteitskosten worden
                                berekend op{' '}
                                {this.state.resultValues.typeCustomer !== 'KMO'
                                  ? '€ 0,28'
                                  : '€ 0,23'}{' '}
                                per kWh.
                              </SubTitle>
                              <SubTitle
                                fontSize='14px'
                                align='left'
                                opacity='0.5'
                              >
                                2. De groen gas kosten om elektriciteit op te
                                wekken met de Elugie-centrale stijgen gemiddeld
                                met 2% per jaar en de jaarlijkse gasverbruik
                                kosten worden berekend op{' '}
                                {this.state.resultValues.typeCustomer !== 'KMO'
                                  ? '€ 0,042'
                                  : '€ 0,035'}{' '}
                                per kWh.
                              </SubTitle>
                              <SubTitle
                                fontSize='14px'
                                align='left'
                                opacity='0.5'
                              >
                                3. De jaarlijkse kosten voor servicecontract en
                                waterverbruik worden berekend volgens de
                                consumentenindex van 2% per jaar.
                              </SubTitle>
                            </div>
                            <br />
                            <div>
                              <SubTitle align='left' opacity='0.5'>
                                Van het voordeel dient u de{' '}
                                {this.state.resultValues.typeCustomer !==
                                'Particulier'
                                  ? 'leasing'
                                  : 'financiering'}{' '}
                                of cash aankoop nog vanaf te trekken om het
                                netto voordeel te zien. De{' '}
                                {this.state.resultValues.typeCustomer !==
                                'Particulier'
                                  ? 'leasing'
                                  : 'financiering'}{' '}
                                of cash aankoop worden betaald met de besparing
                                die wordt gerealiseerd. U betaald enkel het
                                service contract{' '}
                                {this.state.inputValues.preference === 'Leasing'
                                  ? 'en het leasing bedrag aan Elugie'
                                  : 'en het aankoopbedrag aan Elugie'}
                                . Gas of groengas neemt u af bij een energie
                                leverancier al dan niet binnen de Elugie
                                community. Het waterverbruik is een kost bij
                                afname uit het net maar kan ook via regenwater
                                gebeuren. Met toekomstige CO2 taxatie en
                                voordelen voor balanceren van elektriciteit via
                                het net is nog geen rekening gehouden.
                                Berekening enkel ter informatie onder voorbehoud
                                van eventuele prijsafwijkingen.
                              </SubTitle>
                            </div>
                          </Modal.Body>
                        </Modal>
                      </ListLine>
                    </List>
                  </DivLinebreak>
                  <div>
                    <SubTitle opacity='0.5'>
                      De Vlaamse steun ontvangt u na opstart en aanvraag van uw
                      subsidie.{' '}
                      {this.state.inputValues.preference === 'Cash'
                        ? ' Het verhoogd fiscaal voordeel is het netto voordeel dat u geniet voor het desbetreffende fiscaal jaar, enkel voor kmos.'
                        : null}{' '}
                      De Plaatsingskost voor 3 centrales is afhankelijk van uw
                      situatie. Na een bezoek bij u ter plaatse door een lokale
                      Elugie installatie partner kan een prijs op maat van uw
                      situatie worden opgemaakt.
                    </SubTitle>
                  </div>
                </Section>
              </Col>
              <Row style={{textAlign: 'center', paddingBottom: '60px'}}>
                <Col md={12}>
                  <Link to='section1' smooth={true} offset={-70}>
                    <Button>RESERVEER MIJN ELUGIE CENTRALE</Button>
                  </Link>
                </Col>
              </Row>
            </Container>
          </FullRow>
        </Section>
        <Section id='section1'>
          <Row>
            <Col md={12}>
              <div>
                <Title align='center'>
                  Reserveer vandaag nog voor slechts €100
                </Title>
                <SubTitle>
                  Plaatsing van deze nieuwe generatie centrales start begin
                  2021.
                </SubTitle>
              </div>
              <br />
              <br />
            </Col>
          </Row>
          <Row>
            <Col md={{size: 5, order: 12}}>
              <div>
                <div>
                  <BoldTitle align='left'>Samenvatting</BoldTitle>
                </div>
                <DivLinebreak>
                  <Row>
                    <Col md={12}>
                      <div
                        style={{
                          marginTop: '20px',
                          marginButton: '20px',
                        }}
                      >
                        <Image
                          alt='techspec-power-station'
                          width='60%'
                          src={require('../../../assets/images/checkout-power-station.jpg')}
                        />
                      </div>
                    </Col>
                    <Col md={12}>
                      <div>
                        <BoldTitle>
                          {suggestedNumberOfPS} Elugie centrales
                        </BoldTitle>
                      </div>
                    </Col>
                    <br />
                    <br />
                    <Col md={12}>
                      <div
                        style={{
                          paddingBottom: '30px',
                        }}
                      >
                        <Row>
                          <Col xs={6}>
                            <ValueAmount fontSize='40px'>
                              {parseFloat(coverageByPS).toFixed(0)}%
                            </ValueAmount>
                            <SubTitle>Zelfstroom</SubTitle>
                          </Col>
                          <Col className='border-left' xs={6}>
                            {
                              <ValueAmount fontSize='40px'>
                                {co2Emissions}%
                              </ValueAmount>
                            }
                            <SubTitle>Minder CO2 uitstoot </SubTitle>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </DivLinebreak>
                <DivLinebreak>
                  <Row>
                    <Col md={12}>
                      <div>
                        {this.state.inputValues.preference === 'Leasing' ? (
                          <List>
                            <ListLine>
                              <SubTitle>
                                {this.state.resultValues.typeCustomer !==
                                'Particulier'
                                  ? 'Eigen inbreng plaatsingskost'
                                  : 'Totaal prijs na subsidies (incl. btw)'}
                              </SubTitle>
                              <SubTitle>
                                {this.state.resultValues.typeCustomer !==
                                'Particulier'
                                  ? new Intl.NumberFormat('nl-BE', {
                                      style: 'currency',
                                      currency: 'EUR',
                                    }).format(
                                      Math.abs(
                                        estPlacementCost +
                                          flemishInvestmentWKK +
                                          batterySubsidy,
                                      ),
                                    )
                                  : new Intl.NumberFormat('nl-BE', {
                                      style: 'currency',
                                      currency: 'EUR',
                                    }).format(totalPsPriceAfterBenefits)}
                              </SubTitle>
                            </ListLine>
                            <ListLine>
                              <SubTitle>
                                {this.state.resultValues.typeCustomer !==
                                'Particulier'
                                  ? 'Uw maandelijks leasingkost'
                                  : 'Uw maandelijks financieringskost'}
                              </SubTitle>
                              <SubTitle>
                                {new Intl.NumberFormat('nl-BE', {
                                  style: 'currency',
                                  currency: 'EUR',
                                }).format(monthlyLeasing[0])}
                              </SubTitle>
                            </ListLine>
                            <ListLine>
                              <SubTitle>Uw besparing op 20 jaar</SubTitle>
                              <SubTitle>
                                {new Intl.NumberFormat('nl-BE', {
                                  style: 'currency',
                                  currency: 'EUR',
                                }).format(
                                  Math.abs(cumulativeAnnualSavings[19]),
                                )}
                              </SubTitle>
                            </ListLine>
                            <ListLine>
                              <BoldTitle>Vandaag te voldoen</BoldTitle>
                              <BoldTitle>€ 100</BoldTitle>
                            </ListLine>
                          </List>
                        ) : (
                          <List>
                            <ListLine>
                              <SubTitle>
                                Totaal prijs na subsidies{' '}
                                {this.state.resultValues.typeCustomer !==
                                'Particulier'
                                  ? '(excl. BTW)'
                                  : '(incl. BTW)'}
                              </SubTitle>
                              <SubTitle>
                                {new Intl.NumberFormat('nl-BE', {
                                  style: 'currency',
                                  currency: 'EUR',
                                }).format(totalPsPriceAfterBenefits)}
                              </SubTitle>
                            </ListLine>
                            <ListLine>
                              <SubTitle>Uw besparing op 20 jaar</SubTitle>
                              <SubTitle>
                                {new Intl.NumberFormat('nl-BE', {
                                  style: 'currency',
                                  currency: 'EUR',
                                }).format(
                                  Math.abs(cumulativeAnnualSavings[19]),
                                )}
                              </SubTitle>
                            </ListLine>
                            <ListLine>
                              <BoldTitle>Vandaag te voldoen</BoldTitle>
                              <BoldTitle>€ 100</BoldTitle>
                            </ListLine>
                          </List>
                        )}
                      </div>
                    </Col>
                  </Row>
                </DivLinebreak>
                <div style={{paddingBottom: '30px'}}>
                  <SubTitle align='left' opacity='0.5'>
                    U betaald vandaag 100 euro en plaatst een vrijblijvende
                    reservatie voor een eigen zelfstroom centrale. De reservatie
                    is <strong>vrijblijvend</strong> en kan op elk moment door u
                    worden terug geroepen, de 100 euro wordt dan terug betaald.
                  </SubTitle>
                </div>
              </div>
            </Col>
            <Col md={{size: 7, order: 1}}>
              <div>
                <div>
                  <BoldTitle align='left'>Accountgegevens</BoldTitle>
                </div>
                <CheckoutForm price={10} />
                <div style={{paddingTop: '30px', paddingBottom: '30px'}}>
                  <SubTitle align='left' opacity='0.5'>
                    De reservatie is onder voorbehoud goedkeuring van
                    financiering en goedkeuring tot plaatsing. Na de betaling
                    ontvangt u diezelfde week nog een factuur voor reservatie. U
                    kan steeds kosteloos annuleren.
                  </SubTitle>
                </div>
              </div>
            </Col>
          </Row>
        </Section>
      </Container>
    )
  }
}

export default withRouter(Result)
